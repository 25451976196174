<template>
  <tr>
    <td class="date-cell">
      <div class="date-container">
        <div>{{ formattedDate }}</div>
        <div style="text-align: right; width: 100%">{{ formattedTime }}</div>
      </div>
    </td>
    <td class="stadium-cell">{{ match.stadium }}</td>
    <td class="away-cell bold-item"><TeamLabelledFlag  :team="match.homeTeam" side="right" /></td>
    <td class="score-cell bold-item">
      <div class="score-container">
        {{ match.homeTeamScore }} : {{ match.awayTeamScore }}
      </div>
    </td>
    <td class="bold-item"><TeamLabelledFlag  :team="match.awayTeam" side="left" /></td>
  </tr>
</template>

<style>
.date-cell {
  display: none
}
.date-container {
  display: grid;
  place-items: center;
  width: fit-content;
  height: 100%;
}

.stadium-cell {
  display: none
}

.home-cell {
  text-align: right;
}

.score-cell {
  display: table-cell;
}

.score-container {
  display: grid;
  place-items: center;
}

@media screen and (min-width: 750px) {
  .date-cell {
    display: table-cell;
    padding-left: 1rem
  }
}

@media screen and (min-width: 1000px) {
  .stadium-cell {
    display: table-cell;
  }
}

.bold-item {
  font-size: 16px;
  font-weight: 700;
}
</style>

<script setup>
import { defineProps, computed } from 'vue';

import TeamLabelledFlag from '../Team/TeamLabelledFlag.vue';

/*
 *  props
 *    interface Match {
 *      matchDate: Date;
 *      stadium: String;
 *      homeTeam: String;
 *      awayTeam: String;
 *      homeTeamScore: Number;
 *      awayTeamScore: Number;
 *      matchPlayed: Boolean;
 *    }
 *    
 *    interface ScheduleItemProps {
 *      match: Match
 *    }
 */
const props = defineProps({
  match: {
    type: Object,
    required: true,
  }
})

const formattedDate = computed(() => {
  const date = new Date(props.match.matchDate);
  return date.toLocaleDateString();
})

const formattedTime = computed(() => {
  const date = new Date(props.match.matchDate);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
})
</script>