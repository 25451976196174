<template>
  <div class="schedule-page">
    <!-- Heading -->
    <div class="heading">
      League Schedule
    </div>
    <schedule-table class="schedule-table" :schedule="schedule" />
  </div>
</template>

<style scoped>
.schedule-page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 60px 5% 0;
}
.schedule-table {
  width: 100%;
}
</style>

<script setup>
import { ref, onMounted } from 'vue';

import LeagueService from '../services/LeagueService.js';
import ScheduleTable from '../components/Schedule/ScheduleTable.vue';

const schedule = ref([])
const leagueService = new LeagueService();
const getSchedule = async () => {
  await leagueService.fetchData();
  schedule.value = leagueService.getMatches();
}

onMounted(() => {
  getSchedule();
})
</script>